<template>
  <v-card elevation="1">
    <v-toolbar height="45" flat color="accent" light>
      <v-toolbar-title class="white--text">{{ $route.meta.title }}</v-toolbar-title>
    </v-toolbar>
    <v-divider class="mb-2"></v-divider>
    <v-tabs color="accent">
      <v-tab>
        <v-icon left>{{ icons.mdiPlus }}</v-icon>
        Inspection Templates
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-expansion-panels class="mt-2 mb-3" flat multiple v-model="panel">
                <v-expansion-panel v-for="(inspection, index1) in inspections" :key="index1">
                  <v-expansion-panel-header class="mt-5" @click.native.stop="">
                    <template v-slot:actions>
                      <v-icon color="primary" class="icon mt-n10">{{ icons.mdiMenuDown }}</v-icon>
                    </template>
                    <v-layout class="header" row>
                      <v-col cols="12" md="8" sm="8">
                        <v-text-field
                          v-model="inspections[index1].name"
                          color="secondary"
                          class="mt-n5 mx-2"
                          label="Name"
                          dense
                          outlined
                          @click.native.stop=""
                        ></v-text-field>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        class="mt-n1 mx-1"
                        rounded
                        @click.stop=""
                        @click.prevent="saveInspectionTemplate"
                      >
                        <v-icon left dark>{{ icons.mdiContentSave }}</v-icon>
                        <span>Save</span>
                      </v-btn>
                      <v-btn
                        color="error"
                        class="mt-n1"
                        rounded
                        @click.stop=""
                        @click.prevent="$router.push({ name: 'inspectiontemplate' })"
                      >
                        <v-icon left dark>{{ icons.mdiClose }}</v-icon>
                        <span>Cancel</span>
                      </v-btn>
                    </v-layout>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-expansion-panels>
                      <v-expansion-panel
                        v-for="(category, index2) in inspection.inspection_template_category"
                        :key="index2"
                      >
                        <v-expansion-panel-header class="mt-2" @click.native.stop="">
                          <template v-slot:actions>
                            <v-icon color="primary" class="icon mt-n7">{{ icons.mdiMenuDown }}</v-icon>
                          </template>
                          <v-layout class="header" row>
                            <v-col cols="12" md="8" sm="8">
                              <v-autocomplete
                                v-model="category.inspection_cat_id"
                                :items="inspectionCategories"
                                item-value="inspection_cat_id"
                                item-text="name"
                                color="secondary"
                                dense
                                outlined
                                chips
                                deletable-chips
                                small-chips
                                label="Name"
                                single-line
                                class="mx-1"
                                @click.native.stop=""
                              ></v-autocomplete>
                            </v-col>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  @click.stop
                                  @click.prevent="addnewInspectionCategory"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  class="mx-1 mt-4"
                                  fab
                                  x-small
                                >
                                  <v-icon size="20">{{ icons.mdiPlus }}</v-icon>
                                </v-btn>
                              </template>
                              <span>Add New Category</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="info"
                                  @click.stop
                                  @click.prevent="AddInspectionGroup(index1)"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  class="mx-1 mt-4"
                                  fab
                                  x-small
                                >
                                  <v-icon size="20">{{ icons.mdiFolderPlusOutline }}</v-icon>
                                </v-btn>
                              </template>
                              <span>Add New Group</span>
                            </v-tooltip>
                          </v-layout>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-layout v-for="(item, index3) in category.inspection_template_item" :key="index3">
                            <v-col cols="12" md="6" sm="6" class="mt-n9">
                              <v-text-field
                                color="secondary"
                                v-model="category.inspection_template_item[index3].name"
                                class=""
                                label="Assigned Task"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="error"
                                  @click.prevent="removeInspectionItem(index1, index2, index3)"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  class="mt-n5"
                                  fab
                                  x-small
                                >
                                  <v-icon size="20">{{ icons.mdiDeleteCircleOutline }}</v-icon>
                                </v-btn>
                              </template>
                              <span>Remove Item</span>
                            </v-tooltip>
                          </v-layout>
                          <v-btn
                            color="primary"
                            class="mx-1"
                            rounded
                            @click.prevent="addInspectionItem(index1, index2)"
                          >
                            <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                            <span>Add Item</span>
                          </v-btn>
                          <v-btn
                            color="error"
                            class="mx-1"
                            rounded
                            @click.prevent="removeCategoryInspection(index1, index2)"
                          >
                            <v-icon left dark>{{ icons.mdiDeleteCircleOutline }}</v-icon>
                            <span>Delete Group</span>
                          </v-btn>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
    <add-inspectioncategory
      :dialog.sync="showNewInspectionCategoryDialog"
      @open-dialog="showNewInspectionCategoryDialog = true"
      @close-dialog="showNewInspectionCategoryDialog = false"
    />
  </v-card>
</template>
  
  <script>
import { mapGetters } from 'vuex'
import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import {
  mdiAccountTie,
  mdiPlus,
  mdiInformation,
  mdiContentSave,
  mdiClose,
  mdiMenuDown,
  mdiMagnify,
  mdiDotsVertical,
  mdiCheckboxMarkedCircle,
  mdiAlphaRBoxOutline,
  mdiEye,
  mdiContentSaveCog,
  mdiDeleteCircleOutline,
  mdiFolderPlusOutline,
} from '@mdi/js'
import AddNewInspectionCategoryDialog from '@/views/inspectioncategory/AddNewInspectionCategory.vue'

export default {
  components: {
    'add-inspectioncategory': AddNewInspectionCategoryDialog,
  },
  created: function () {
    this.getInsectionCategories()

    window.mitt.on('loadInspectionCategory', () => {
      this.getInsectionCategories()
    })
  },
  data() {
    return {
      panel: [0, 1],
      inspections: [
        {
          inspection_id: '',
          name: '',
          inspection_template_category: [
            {
              inspection_grp_id: '',
              inspection_id: '',
              inspection_cat_id: '',
              inspection_template_item: [
                {
                  inspection_item_id: '',
                  inspection_cat_id: '',
                  name: '',
                },
              ],
            },
          ],
        },
      ],
      inspectionCategories: [],
      inspectionCategory: {
        inspection_cat_id: '',
        name: '',
      },
      showNewInspectionCategoryDialog: false,
      showEditInspectionCategoryDialog: false,
      icons: {
        mdiAccountTie,
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
        mdiMenuDown,
        mdiMagnify,
        mdiDotsVertical,
        mdiCheckboxMarkedCircle,
        mdiAlphaRBoxOutline,
        mdiEye,
        mdiContentSaveCog,
        mdiDeleteCircleOutline,
        mdiFolderPlusOutline,
      },
    }
  },
  methods: {
    getInsectionCategories: function () {
      this.$store
        .dispatch('inspectionTemplate/fetchInspectionCategories')
        .then(response => {
          ;(this.inspectionCategories = this.fetchInspectionCategories), response
        })
        .catch(err => {
          console.log(err)
        })
    },
    saveInspectionTemplate: function () {
      this.$store
        .dispatch('inspectionTemplate/storeNewInspection', {
          inspections: this.inspections,
        })
        .then(response => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })

          Toast.fire({
            icon: 'success',
            title: 'Record added successfully',
          })
          this.$router.push({ name: 'inspectiontemplate' })
        })
        .catch(err => {
          console.log(err)
        })
    },
    addnewInspectionCategory: function () {
      this.showNewInspectionCategoryDialog = true
    },
    AddInspectionGroup: function (index1) {
      this.inspections[index1].inspection_template_category.push({
        inspection_grp_id: '',
        inspection_id: '',
        inspection_cat_id: '',
        inspection_template_item: [
          {
            inspection_item_id: '',
            inspection_grp_id: '',
            name: '',
          },
        ],
      })
    },

    removeCategoryInspection: function (index1, index2) {
      this.inspections[index1].inspection_template_category.splice(index2, 1)
    },
    addInspectionItem: function (index1, index2) {
      this.inspections[index1].inspection_template_category[index2].inspection_template_item.push({
        inspection_item_id: '',
        inspection_grp_id: '',
        name: '',
      })
    },

    removeInspectionItem: function (index1, index2, index3) {
      this.inspections[index1].inspection_template_category[index2].inspection_template_item.splice(index3, 1)
    },
  },

  computed: {
    ...mapGetters({
      fetchInspectionCategories: 'inspectionTemplate/fetchInspectionCategories',
    }),
  },
}
</script>
  
  <style lang="scss" scoped>
.icon {
  order: 0;
}
.header {
  order: 1;
}
</style>